// import { defineAsyncComponent } from 'vue'

const req = require.context('./', true, /\.vue$/)

const requireAll = requireContext => requireContext.keys().map(requireContext)

const components = requireAll(req)

const install = (app) => {
    components.forEach(component => {
        app.component(component.default.name, component)
    })
}

export default {
    install
}
