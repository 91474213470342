<template>
  <div class="main">
    <HeaderView />
    <router-view />
  </div>
</template>

<script>
import HeaderView from './views/header.vue'

export default {
  name: 'App',
  components: {
    HeaderView
  },
  created() {
    document.addEventListener('gesturestart', function (event) {
      // 阻止兩指縮放畫面
      event.preventDefault();
    });
  },
  mounted() {
    // 檢查是否有購物車資料
    this.$store.commit('order/GET_SHOPCART_COOKIE')
    // 重新計算
    this.$store.commit('order/UPDATE_TOTAL_AMOUNT')

  }
}
</script>

<style lang="scss">
#app {
  min-height: 100vh;
}
</style>

<style lang="scss" scoped>
.main {
  display: flex;
  width: 100%;
  min-height: 100vh;
  margin: 0px auto;
  position: relative;
  max-width: 768px;
  flex-direction: column;
  touch-action: manipulation;

  background-color: rgb(255, 255, 255);
  color: rgb(66, 66, 66);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;

}
</style>