import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { getToken } from '@/utils/auth'
import store from "@/store";

const routes = [

    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/product/:id",
        name: "product",
        component: () => import("../views/ProductView.vue"),
    },
    {
        path: "/shopcart/:index",
        name: "shopcartEdit",
        component: () => import("../views/ProductView.vue"),
    },
    {
        path: "/shopcart",
        name: "cart",
        component: () => import("../views/ShopCartView.vue"),
    },
    {
        path: "/order",
        name: "orderList",
        component: () => import("../views/OrderedView.vue"),
    },
    {
        path: "/checkin",
        name: "checkin",
        component: () => import("../views/CheckinView.vue"),
    },
    {
        path: "/exit",
        name: "exit",
        component: () => import("../views/ExitView.vue"),
    },
    {
        path: '/401',
        name: 'NoPermission',
        component: () => import('@/views/error-page/401'),
    },
    {
        path: '/:catchAll(.*)', // 匹配所有路径
        name: 'NotFound',
        component: () => import('@/views/error-page/404'),
    }
];

const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHashHistory(),
    routes,
    linkActiveClass: "is-active",
    linkExactActiveClass: "is-active",
    scrollBehavior() {
        // always scroll to top
        return { top: 0 }
    },
});

// 路由守衛
router.beforeEach(async (to, from, next) => {

    // 設定title
    document.title = to.meta.title || '食荐鍋物';

    // 檢查有無token
    if (to.fullPath.indexOf('exit') === -1 && to.fullPath.indexOf('checkin') === -1 && to.fullPath.indexOf('404') === -1) {
        const token = getToken();
        if (!token) {
            next({ path: '/404' });
        } else if (token && !store.state.auth.seat) {
            await store.dispatch('auth/userSit').catch((err) => {
                console.error('入座失敗', err);
                next({ path: '/404' });
            });
        }
        if (token && store.state.app.webConfig.size === 0) {
            // 取得webConfig
            await store.dispatch('app/getWebConfig').catch(err => {
                console.error('取得webConfig失敗', err)
            })
        }
    }
    next();
});

export default router;
