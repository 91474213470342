import Cookies from 'js-cookie'

const state = {
    shopCart: [],
    totalAmount: 0,
}

const mutations = {
    // 增加一項商品到購物車
    ADD_SHOPCART(state, payload) {
        state.shopCart.push(payload);
        // 增加總金額
        state.totalAmount += payload.price * payload.quantity;
        // 將購物車資料轉JSON存入cookie 限時2小時
        Cookies.set('shopCart', JSON.stringify(state.shopCart), { expires: 2 / 24 });
    },
    // 從cookie取出購物車資料
    GET_SHOPCART_COOKIE(state) {
        // console.log("從cookie取出購物車資料")
        const shopCart = Cookies.get('shopCart');
        if (shopCart) {
            state.shopCart = JSON.parse(shopCart);
        }
    },
    // 變更數量
    UPDATE_QUANTITY(state, { index, product }) {
        // console.log('UPDATE_QUANTITY', index, product)
        state.shopCart[index] = product;
    },
    // 變更總金額
    UPDATE_TOTAL_AMOUNT(state) {
        state.totalAmount = 0;
        state.shopCart.forEach(item => {
            state.totalAmount += item.price * item.quantity;
        })
    },
    // 刪除購物車商品
    DELETE_PRODUCT(state, index) {
        state.shopCart.splice(index, 1);
    },
    // 購物車資料存入cookie
    SAVE_SHOPCART(state) {
        Cookies.set('shopCart', JSON.stringify(state.shopCart), { expires: 2 / 24 });
    },
    // 清空購物車
    RESET_SHOPCART(state) {   
        state.shopCart = [];
        state.totalAmount = 0;
        Cookies.remove('shopCart');
    }

}

const actions = {
}

const getters = {
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
