import request from '@/utils/request'

// 取得菜品類別
export function getProductType(params) {
    return request({
        url: '/dishCategory',
        method: 'get',
        params
    })
}

// 取得菜品列表
export function getProductList(params) {
    const queryString = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&')
    return request({
        url: `/product${queryString ? `?${queryString}` : ''}`,
        method: 'get'
    })
}

// 取得菜品詳情
export function getProductDetail(id) {
    return request({
        url: `/product/${id}`,
        method: 'get'
    })
}