import { userSit } from '@/api/auth'
import { getToken, setToken, removeToken } from '@/utils/auth'

const state = {
    token: getToken(),
    seat: null
}

const mutations = {
    SET_SEAT: (state, data) => {
        state.seat = data
    },
    SET_TOEKN: (state, token) => {
        state.token = token
        setToken(token)
    },
    REMOVE_TOKEN: (state) => {
        state.token = null
        removeToken()
    }
}

const actions = {
    userSit({ commit }) {
        return new Promise((resolve, reject) => {
            userSit().then(response => {
                commit('SET_SEAT', response.data.seatData)
                resolve(response)
            }).catch(error => {
                removeToken()
                reject(error)
            })
        })
    }
}

const getters = {
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}



