import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import store from './store'


// filter
import filters from './utils/filters' // global filters

// Element Plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// import './icons' // icon
import components from '@/components/index'

import '@/styles/index.scss' // global css

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)

app.use(store)
app.use(router)
app.use(ElementPlus)
app.use(components)

app.config.globalProperties.$filters = filters

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.mount('#app')