<template>
    <div class="header">
        <div class="header__logo">
            <img src="../assets/logo.png" alt="logo" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderView',
    components: {},
    data() {
        return {};
    },
    computed: {},
    created() {},
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.header {

    position: fixed;
    max-width: 768px;
    width: 100%;
    margin: auto;
    background-color: rgb(255, 255, 255);
    z-index: 1000;
    top: 0px;
    opacity: 1;
    transition: all 0.25s ease 0s;
    height: 55px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 3px rgba(26, 26, 26, 0.1);

    .header__logo {
        // width: 100px;
        height: 70%;

        img {
            height: 100%;
        }
    }
}
</style>
