<template>
    <div class="content menu-content">
        <div class="image-wrapper">
            <img :src="webConfig.get(0)" alt="Vue logo" />
        </div>
        <div class="info-wrapper">
            <div class="info-title">
                <p v-if="$store.state.auth.seat">
                    <img class="img-icon dinner-icon" src="../assets/icons/dinner.png" alt="dinner" />
                    {{ $store.state.auth.seat.number }}桌
                </p>
                <el-button class="ordered-btn" round @click="$router.push('/order')">
                    <el-icon size="16">
                        <Checked />
                    </el-icon>已點項目
                </el-button>
            </div>
        </div>
        <hr />
        <div class="about-wrapper" @click="changeAbout">
            <div class="about-title">
                <div class="about-title-text">
                    <img class="img-icon chat-icon" src="../assets/icons/loud-speaker.png" alt="chat" />
                    <p>提醒事項</p>
                </div>
                <div class="about-title-btn" :class="[{ up: !showAbout }, { down: showAbout }]"></div>
            </div>
            <div class="about-content" :class="[
                { hide: showAbout },
                { show: !showAbout },
                { 'three-line': threeLine },
            ]">
                <p v-html="webConfig.get(1)"></p>
            </div>
        </div>
        <hr />
        <div class="type-wrapper">
            <div class="type-wrapper-absolute">
                <div class="type-wrapper-list" :class="[{ 'is-active': showType }]">
                    <div v-for="(catagary, key) in productType" :key="key" class="type-wrapper-list-item"
                        :id="`typeBtn${catagary.id}`" :class="[{ 'is-active': catagary.id === typeActive }]"
                        @click="typeTagClick(catagary.id)">
                        <div class="type-item-block">
                            <span>{{ catagary.name }}</span>
                        </div>
                    </div>
                </div>
                <button class="type-show cJmZZH" @click="showType = !showType">
                    <span class="type-show-btn" :class="[{ up: showType }, { down: !showType }]"></span>
                </button>
            </div>
            <div v-if="showType" class="type-mask" @click="showType = false"></div>
        </div>
        <div class="product-wrapper">
            <template v-for="(catagary, key) in productType" :key="key + '1'">
                <div class="type-div" :id="`productType${catagary.id}`">
                    <ul class="product-list">
                        <li class="product-list-title">
                            <h4>{{ catagary.name }}</h4>
                        </li>
                        <template v-for="(product, key) in productList[catagary.id]" :key="key + '2'">

                            <li v-if="product.requires_inventory && product.inventory_quantity === 0"
                                class="product-list-item is-soldout">
                                <div class="item-content">
                                    <div class="item-info">
                                        <div class="name">
                                            <span>{{ product.name }}</span>
                                            <div class="tags-div">
                                                <el-image v-for="(tag, index) in product.tags" :key="index"
                                                    :src="tag.icon_url" />
                                            </div>
                                        </div>
                                        <div class="desc" v-html="product.description"></div>
                                        <div class="price">
                                            <span>NT$
                                                {{ $filters.toThousandFilter(product.price) }}</span>
                                        </div>
                                    </div>
                                    <div class="product-right">
                                        <div v-if="product.image_url" class="product-right-div">
                                            <el-image :src="product.image_url" lazy />
                                        </div>
                                        <div class="soldout-div">
                                            <span>售完</span>
                                        </div>
                                    </div>

                                </div>
                            </li>
                            <li v-else class="product-list-item" @click="gotoDetail(product.id)">
                                <div class="item-content">
                                    <div class="item-info">
                                        <div class="name">
                                            <span>{{ product.name }}</span>
                                            <div class="tags-div">
                                                <el-image v-for="(tag, index) in product.tags" :key="index"
                                                    :src="tag.icon_url" />
                                            </div>
                                        </div>
                                        <div class="desc" v-html="product.description"></div>
                                        <div class="price">
                                            <span>NT$
                                                {{ $filters.toThousandFilter(product.price) }}</span>
                                        </div>
                                    </div>
                                    <div class="product-right">
                                        <div v-if="product.image_url" class="product-right-div">
                                            <el-image :src="product.image_url" lazy />
                                        </div>
                                        <div v-if="orderedList[product.id]" class="order-count">
                                            <span>{{ orderedList[product.id].quantity }}</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <hr />
                        </template>
                    </ul>
                </div>
                <div class="middle-separate"></div>
            </template>
        </div>
        <div class="shopcar-wrapper">
            <button class="cJmZZH" @click="$router.push('/shopcart')">
                <span class="price">NT$ {{ $filters.toThousandFilter(totalAmount) }}</span>
                <span class="show-car-txt">查看購物車</span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "HomeView",
    components: {},
    data() {
        return {
            showAbout: false,
            threeLine: false,
            showType: false,
            typeActive: 0,
            typeBtnLock: false,
            orderedList: {}, // 已點餐品
        };
    },
    computed: {
        productType() {
            return this.$store.state.product.productType;
        },
        productList() {
            return this.$store.state.product.productList;
        },
        totalAmount() {
            return this.$store.state.order.totalAmount;
        },
        webConfig() {
            return this.$store.state.app.webConfig;
        },
    },
    watch: {
        typeActive(val) {
            this.$nextTick(() => {
                const activeType = document.getElementById(`typeBtn${val}`);
                document.querySelector(".type-wrapper-list").scrollLeft =
                    activeType.offsetLeft - 10;
            });
        },
    },
    created() {
        this.$store.dispatch("product/getProductType").catch((err) => {
            console.error("取得菜品類型失敗", err);
        });
        this.$store.dispatch("product/getProductList").catch((err) => {
            console.error("取得菜品列表失敗", err);
        });

        // 統計已點餐品
        this.countOrder();
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        changeAbout() {
            this.showAbout = !this.showAbout;
            if (this.showAbout) {
                // 沿時0.5秒
                setTimeout(() => {
                    this.threeLine = true;
                }, 300);
            } else {
                this.threeLine = false;
            }
        },
        typeTagClick(val) {
            this.typeBtnLock = true;
            this.typeActive = val;
            this.showType = false;
            this.$nextTick(() => {
                const el = document.getElementById(`productType${val}`);
                const scrollDiv = document.getElementsByTagName("html")[0];
                scrollDiv.scrollTop = el.offsetTop - 110;
                setTimeout(() => {
                    this.typeBtnLock = false;
                }, 500);
            });
        },
        handleScroll() {
            if (this.typeBtnLock) return;
            // 取得html目前滑動高度
            const htmlScrollPosition =
                document.getElementsByTagName("html")[0].scrollTop;

            // 取得第一個 type-div 的高度
            const firstTypeDiv =
                document.getElementsByClassName("type-div")[0]?.offsetTop - 110;
            if (htmlScrollPosition < firstTypeDiv) return;

            // 取得 type-div 跑回圈判斷在哪個位置
            let now = this.productType[0].id;
            for (let typeItem of this.productType) {
                const typeDiv =
                    document.getElementById(`productType${typeItem.id}`).offsetTop - 110;
                if (htmlScrollPosition < typeDiv) {
                    break;
                }
                now = typeItem.id;
            }

            this.typeActive = now;
        },
        gotoDetail(id) {
            this.$router.push(`/product/${id}`);
        },
        // 統計已點餐品
        countOrder() {
            const orderList = {};
            for (const product of this.$store.state.order.shopCart) {
                if (orderList[product.id]) {
                    orderList[product.id].quantity += product.quantity;
                } else {
                    orderList[product.id] = {
                        id: product.id,
                        quantity: product.quantity,
                    };
                }
            }
            this.orderedList = orderList;
        },
    },
};
</script>
