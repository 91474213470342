import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(config => {
  if (getToken() != null) {
    config.headers['Authorization'] = 'Bearer ' + getToken()
    // 不允許快取
    config.headers['Cache-Control'] = 'no-cache'
  }
  return config
},
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(response => {
  const httpCode = response.status
  const res = response.data
  if (httpCode !== 201 && httpCode !== 200) {
    ElMessage({
      message: res.message || 'Error',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(res.message || 'Error')
  } else {
    return res
  }
},
  error => {

    if (error.response.status === 401 || error.response.status === 403) {

      ElMessageBox.confirm('本次操作已逾時，請重新掃描點餐或關閉視窗！', '已登出', {
        confirmButtonText: '確定',
        showCancelButton: false,
        type: 'warning'
      }).then(() => {
        location.href = '/#/401'
      }).catch(() => {
        location.href = '/#/401'
      })
      // 处理自定义 Token 错误并返回适当的错误状态码
      return Promise.reject({ status: error.response.status, message: error.response.data.message });
    } else if(!/^4\d{2}$/.test(error.response.status)){
      ElMessage({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
    }
    // console.error('err:' + error) // for debug
    return Promise.reject(error)
  }
)

export default service
