import { getWebConfig } from '@/api/app'


const state = {
    webConfig: new Map()
}

const mutations = {
    SET_WEB_CONFIG: (state, data) => {
        state.webConfig = data
    }
}

const actions = {
    getWebConfig({ commit }) {
        return new Promise((resolve, reject) => {
            getWebConfig().then(response => {
                // 轉成MAP
                let map = new Map()
                for (let i = 0; i < response.data.length; i++) {
                    map.set(response.data[i].kind, response.data[i].content)
                }
                commit('SET_WEB_CONFIG', map)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    }
}

const getters = {
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
