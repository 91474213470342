import Cookies from 'js-cookie'

const TokenKey = 'posCustomerToken'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  // 把token存入cookie 有效期為2小時
  return Cookies.set(TokenKey, token, { expires: 2 / 24 })
}

export function removeToken() {
  const cookies = Cookies.get()
  Object.keys(cookies).forEach(name => {
    Cookies.remove(name)
  })
  return true
}
